<template>
    <div class="px-5 py-5">
       <v-col md="12"></v-col>
       <div>
       <template>
           <v-row>
                <v-col md="2">
                    <v-autocomplete :items="users" item-text="name" item-value="id" v-model="filters.sender_id"
                                            @keypress="fetchUsers" :loading="usersLoading"
                                            :label="$t('Sender')"   ></v-autocomplete>
                </v-col>
                <v-col md="2">
                    <v-autocomplete :items="users" item-text="name" item-value="id" v-model="filters.receiver_id"
                                            @keypress="fetchUsers" :loading="usersLoading"
                                            :label="$t('Receiver')"   ></v-autocomplete>
                </v-col> 
                <v-col md="2">
                    <v-select
                    v-model="filters.status"  
                    color="#757575" 
                    :label="$t('type')"
                    :items="typeListFilter"
                    item-text="name"
                    item-value="value"
                    ></v-select>
                </v-col> 
                <v-col md="2">
                    <v-text-field   :label="$t('hr.time.from-date')" type="date" 
                                    v-model="filters.dead_line_from">
                    </v-text-field>
                </v-col>
                <v-col md="2">
                    <v-text-field   :label="$t('hr.time.to-date')" type="date" 
                                    v-model="filters.dead_line_to">
                    </v-text-field>
                </v-col>
                <v-col md="1" class="py-7">
                    <v-icon large color="gray" @click="clearFilter">mdi-close</v-icon>
                </v-col>
                <v-col md="1" class="py-7">
                    <v-icon large color="gray" @click="getAll"> mdi-magnify </v-icon>
                </v-col>

           </v-row>
           
   
       </template>
       </div>
       <v-data-table :dark="$store.state.isDarkMode"
       :headers="headers"
       :loading="loading"
       :items="items"
       >
       <template v-slot:item.actions="{item}">
           
           <v-btn  v-if="item.status == 'sent' && item.is_my" @click="doneTasks(item)" color="blue" icon>
           <v-icon class="ml-1"> mdi-check </v-icon></v-btn
       >
       <v-btn  v-if="item.status == 'done' && item.is_my" @click="nonDoneTasks(item)" color="red" icon>
           <v-icon class="ml-1"> mdi-close </v-icon></v-btn
       >
       <v-btn  v-if="item.status == 'done' && !item.is_my" @click="aproveTasks(item)" color="blue" icon>
           <v-icon class="ml-1"> mdi-check-all </v-icon></v-btn
       >
       <v-btn  v-if="item.status == 'approved' && !item.is_my" @click="nonAproveTasks(item)" color="red" icon>
           <v-icon class="ml-1"> mdi-close </v-icon></v-btn
       >

       <v-btn  @click="showMessage(item)" color="green" icon>
           <v-icon class="ml-1">mdi-eye</v-icon></v-btn
       >
       </template>
       </v-data-table>
    </div>
   </template>
   
   <script>
   
   import axios from "axios";
   import i18n from '@/i18n';
   import debounce from "../../../../helpers/debounce";
   
   export default {
   components: {
   },
   data() {
       return {
           typeListFilter: [
           {
             name: i18n.t("Sent"),
             value: "sent",
           },
           {
             name: i18n.t("Approved"),
             value: "approved",
           },
           {
             name: i18n.t("Done"),
             value: "done",
           },
         ],
       headers: [
           { text: i18n.t("date"), value: "date" },
           { text: i18n.t("Sender"), value: "sender.name" },
           { text: i18n.t("Receiver"), value: "receiver.name" },
           { text: i18n.t("name"), value: "name"},
           { text: i18n.t("Deadline"), value: "dead_line"},
           { text: i18n.t("status"), value: "status"},
           { text: "", value: "actions" },
       ],
       items: [],
       usersLoading:null,
       users: [],
       loading: false,
       filters: {
           receiver_id: null,
           sender_id: null,
           status: null,
           dead_line_from: null,
           dead_line_to: null,
       },
       };
   },
   methods: {
    async aproveTasks(item){
        const res = await axios.post(`/task/approve/${item.id}`, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            if (res.status == 200) {
            this.$Notifications(
              "تم إكمال المهمة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.getAll();
          }
    },
    async nonAproveTasks(item){
        const res = await axios.post(`/task/approve/${item.id}`, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            if (res.status == 200) {
            this.$Notifications(
              "تم إلغاء إكمال المهمة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.getAll();
          }
    },
    async doneTasks(item){
        const res = await axios.post(`/task/done/${item.id}`, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            if (res.status == 200) {
            this.$Notifications(
              "تم إكمال المهمة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.getAll();
          }
    },
    async nonDoneTasks(item){
        const res = await axios.post(`/task/done/${item.id}`, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });

            if (res.status == 200) {
            this.$Notifications(
              "تم إلغاء إكمال المهمة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.getAll();
          }
    },
       showMessage(item){
           
             this.$router.push(
                   `showMessage/${item.message_id}`
                 );
           
         },
       async fetchUsers(searchQuery) {
           debounce(async () => {
               let searchValue = searchQuery.target.value;
               this.usersLoading = true;
               if (!searchValue) return;
               try {
               const searchResult = await axios.get("/user/auto-complete", {
                   params: {
                   name: searchValue,
                   },
               });
               this.users = searchResult.data.users;
               } catch (err) {
               console.log("err", err);
               } finally {
               this.usersLoading = false;
               }
           }, 500)();
       },
       clearFilter() {
        
       this.filters.receiver_id = undefined;
       this.filters.sender_id = undefined;
       this.filters.dead_line_from = undefined;
       this.filters.dead_line_to = undefined;
       this.filters.status = undefined;
       
       
       // this.page = 1;
       this.getAll();
       },
       async getAll() {
       const filterTemp = {};
           Object.keys(this.filters).forEach((key) => {
           if (this.filters[key]) filterTemp[key] = this.filters[key];
           });
       this.loading = true;
       const res = await axios.get("/task",{
           params: {
               
               ...filterTemp,
           },
           });
       
       const arr= Array.from(res.data.data)
           if(Array.isArray(arr))
           {
           console.log(arr);
           this.items = arr.map((el) => {
               
               return {
               ...el,
               
               };
           });
           }
           console.log(this.items);
       
       this.loading = false;
       },
   },
   created() {
       this.getAll();
   },
   };
   </script>
   
   <style></style>
   